import React from 'react';
import styled from 'styled-components';

const StyledSpotify = styled.section`
  width: fit-content;
  height: 100px;
  border-radius: 12px;
  overflow: hidden;
  margin: auto;
  padding: 0;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
`;

export default function Spotify() {
  return (
    <StyledDiv>
      <StyledSpotify>
        <iframe
          //   style="border-radius:12px"
          src="https://open.spotify.com/embed/album/1jktyxNVwYBwiARCZCsXBA?"
          //   width="100%"
          //   height="152"
          frameBorder="0"
          allowFullScreen={true}
          title={`Lights to Low - MAX`}
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          loading="lazy"></iframe>
      </StyledSpotify>
      <StyledSpotify>
        <iframe
          //   style="border-radius:12px"
          src="https://open.spotify.com/embed/track/4yNk9iz9WVJikRFle3XEvn?"
          //   width="100%"
          //   height="152"
          frameBorder="0"
          allowFullScreen={true}
          title={`When you're gone - Shawn Mendis`}
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          loading="lazy"></iframe>
      </StyledSpotify>
    </StyledDiv>
  );
}
