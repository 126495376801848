import React, { useEffect, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

const StyledAboutSection = styled.section`
  max-width: 900px;

  .inner {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;
const StyledText = styled.div`
  p {
    font-size: var(--fz-lg);
  }
`;
const StyledPic = styled.div`
  position: relative;
  max-width: 300px;

  .tech-list {
    margin-top: 40px;
    font-size: var(--fz-lg);
  }

  @media (max-width: 768px) {
    margin: 50px auto 0;
    width: 70%;
  }
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    grid-gap: 0 10px;
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);

      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }

  .wrapper {
    ${({ theme }) => theme.mixins.boxShadow};
    display: block;
    position: relative;
    width: 100%;
    border-radius: var(--border-radius);
    background-color: var(--green);

    &:hover,
    &:focus {
      outline: 0;

      &:after {
        top: 15px;
        left: 15px;
      }

      .img {
        filter: none;
        mix-blend-mode: normal;
      }
    }

    .img {
      position: relative;
      border-radius: var(--border-radius);
      mix-blend-mode: multiply;
      filter: grayscale(100%) contrast(1);
      transition: var(--transition);
    }

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);
      transition: var(--transition);
    }

    &:before {
      top: 0;
      left: 0;
      background-color: var(--light-red);
      mix-blend-mode: screen;
    }

    &:after {
      border: 2px solid var(--green);
      top: 20px;
      left: 20px;
      z-index: -1;
    }
  }
`;

const About = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  const skills = [
    'Java',
    'Python',
    'RestFul APIs',
    'Microservices',
    'React.js',
    'TypeScript',
    'Kubernetes',
    'Databases',
  ];

  return (
    <StyledAboutSection id="about" ref={revealContainer}>
      <h2 className="numbered-heading">About Me</h2>

      <div className="inner">
        <StyledText>
          <div>
            <p>
              Hi There! I am Tony Mathen a CS grad from Santa Clara University with 2 years of
              experience as a Software Engineer. I am passionate about software development
              especially distributed systems and a strong believer of impacting lives with code.
            </p>

            <p>
              I have always been fascinated by how technology can impact the society and change its
              fabric. I realized this in my undergrad, when I undertook a project of digitizing a
              small manufacturing firm and thereby optimizes the firm production process. This
              experience has ever since motivated me pursue a career in computer science and be part
              of a group that unleashes its potential.
            </p>

            <p>
              Currently, I am leveraging my software engineering skills in creating a mobile
              application for an NGO and engineering a software solution that aims customers to find
              ethically produced products. Additionally, I am strong believer of the open source
              movement and thus I have also started contributing to projects like Hyperledger
              Fabric.
            </p>
          </div>
        </StyledText>

        <StyledPic>
          <div className="wrapper">
            <StaticImage
              className="img"
              src="../../images/me.png"
              width={500}
              quality={95}
              formats={['AUTO', 'WEBP', 'AVIF']}
              alt="Headshot"
            />
          </div>
          <div className="tech-list">
            <p>Here are a few technologies I’ve been working with recently:</p>
            <ul className="skills-list">
              {skills && skills.map((skill, i) => <li key={i}>{skill}</li>)}
            </ul>
          </div>
        </StyledPic>
      </div>
    </StyledAboutSection>
  );
};

export default About;
