import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="A">
        <text
          x="50%"
          y="50%"
          dominantBaseline="middle"
          textAnchor="middle"
          fontSize="60"
          fontWeight="bold"
          fill="currentColor">
          tm{/* &#119964; */}
        </text>
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        d="M50,2.5
       A 47.5,47.5 0 1,1 50,97.5
       A 47.5,47.5 0 1,1 50,2.5"
      />
    </g>
  </svg>
);

export default IconLogo;
